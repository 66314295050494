@tailwind base;
@tailwind components;
@tailwind utilities;


.less_cool_button {
	color: #161515;
  border: 2px solid #694949;
  transition: box-shadow 0.3s ease;
}

.less_cool_button:hover {
  border: 2px solid black;
        box-shadow: 
          0 0 #0000,
          0 0 #0000,
          0px 0.3em 0px 0px #1a081c;
  transition: box-shadow 0.3s ease;
}

.less_cool_button_with_shadow {
  border: 2px solid black;
  transition: box-shadow 0.3s ease;
        box-shadow: 
          0 0 #0000,
          0 0 #0000,
          0px 0.3em 0px 0px #1a081c;
}

.less_cool_button_with_shadow:hover {
  border: 2px solid black;
        box-shadow: 
          0 0 #0000,
          0 0 #0000,
          0px 0.5em 0px 0px #1a081c;
  transition: box-shadow 0.3s ease;
}

.cool_button {
	color: white;
	background: #f52c2d;
  	border: 2px solid black;
    box-shadow: 
          0 0 #0000,
          0 0 #0000,
          0px 0.2em 0px 0px #1a081c;
    transition: box-shadow 0.3s ease;
}
.cool_button:hover {
        box-shadow: 
          0 0 #0000, 
          0 0 #0000, 
          0px 0.4em 0px 0px #1a081c;
}

.half_cool_border {
	box-shadow: 
          0 0 #0000, 
          0 0 #0000, 
          0px 0.4em 0px 0px #1a081c;
}

.cool_border {
	box-shadow: 
          0 0 #0000, 
          0 0 #0000, 
          0px 0.8em 0px 0px #1a081c;
}